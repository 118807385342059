import React from 'react';
import { connect } from "react-redux";
import {TimeOffComponent} from './TimeOff.Component';
import { httpServices } from '../../../services/httpServices';
import { history } from './../../../helpers/history';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import MixpanelManager from  '../../../services/Analytics';
import { getRelativeUrl } from '../../common/common';
import { Tabs } from '../../../redux/store/reducers/tabs';
import { setSavedFilters, updateSavedFilterAction } from '../../../redux/store/reducers/savedFilters';

const filterFields = {
    'my': {
        'startDate' : null,
        'endDate' : null,
        'requestStatus' : 0,
        'userId' : 0,
        'employeeStatus' : 0,
        'task' : 0,
    } ,
    'review': {
        'startDate' : null,
        'endDate' : null,
        'requestStatus' : 1,
        'userId' : 0,
        'employeeStatus' : 1,
        'task' : 0,
    } ,
    'all':{
        'startDate' : null,
        'endDate' : null,
        'requestStatus' : 0,
        'userId' : 0,
        'employeeStatus' : 0,
        'task' : 0,
    }

}
class TimeOffContainer extends React.Component{
    constructor(props){
        super(props);
        const timeoffTabSelected = this.props.selectedTimeoffTab;
        let filter = filterFields[timeoffTabSelected];
        this.state ={
            timeoffTabSelected: timeoffTabSelected,
            myRequests : [],
            allRequests : [],
            reviewRequests : [],
            open : false,
            grantOpen : false,
            filterViewState : false,
            filterFields : filter,
            users : [],
            requestStatus : [],
            employeeStatus : [],
            filterTOTasks : [],
            listViewPermission :true,
            editId : 0,
            snackbarOpen : false,
            snackbarMsg : '',
            reviewCount : 0,
            allRequestViewPermission : false,
            requestView: false,
            timeoffEditPermission : false,
            timeoffReviewPermission : false,
            timeoffGrantPermission : false,
            externalUser : false,
            showLoader: true,
            dataLoaded: false,
            periodStartDate: new Date (),
            periodEndDate: new Date (),
            errors : {},
            saveFilter : false,
            counter : 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.getTimeOffDetails = this.getTimeOffDetails.bind(this);
        this.createTimeOffClick = this.createTimeOffClick.bind(this);
        this.grantTimeOffClick = this.grantTimeOffClick.bind(this);
        this.handleViewClick = this.handleViewClick.bind(this);
        this.updateParentOnAddSuccess = this.updateParentOnAddSuccess.bind(this);
        this.filterView = this.filterView.bind(this);
        this.filterSubmit = this.filterSubmit.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.closeLoader = this.closeLoader.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.MixpanelManager = new MixpanelManager();
        this.getSavedFilter = this.getSavedFilter.bind(this);
        this.saveFilter = this.saveFilter.bind(this);
        this.saveFilterToStore = this.saveFilterToStore.bind(this);
        this.deleteSavedFilter = this.deleteSavedFilter.bind(this);
    }
    componentDidMount = () => {
        var fromDashboard = (this.props.location.state && this.props.location.state.fromDashboard) ? this.props.location.state.fromDashboard : false;        
        if (fromDashboard) {
            var dashboardFilter = (this.props.location.state && this.props.location.state.dashboardFilter) ? this.props.location.state.dashboardFilter : [];
            let startDate = (dashboardFilter.timeoffRequestsStartDate && dashboardFilter.timeoffRequestsStartDate != '') ? dashboardFilter.timeoffRequestsStartDate : null;
            let endDate = (dashboardFilter.timeoffRequestsEndDate && dashboardFilter.timeoffRequestsEndDate != '') ? dashboardFilter.timeoffRequestsEndDate : null;
            let filterFields = this.state.filterFields;
            filterFields['startDate'] = startDate;
            filterFields['endDate'] = endDate;
            filterFields['requestStatus'] = 1;
            filterFields['userId'] = 0;
            filterFields['employeeStatus'] = 0;
            filterFields['task'] = 0;
            this.setState({
                filterFields: filterFields
            });
        }
        let tab = this.state.timeoffTabSelected;
        let actionFrom = (fromDashboard) ? 'dashboard' : '';      
        this.props.setSavedFilterVal(); 
        this.getTimeOffDetails(tab, actionFrom);               
    };
    componentDidUpdate = (prevProps) => {
        let fromDashboard = (this.props.location.state && this.props.location.state.fromDashboard) ? this.props.location.state.fromDashboard : false;
        let actionFrom = (fromDashboard) ? 'dashboard' : '';
        // Fetching timeoff details based on the redux store values after store values are set initially
        if (prevProps.savedFilterValues.length > 0 && this.state.counter === 0) {
            this.setState({
                counter: 1
            }, () => this.getTimeOffDetails(this.state.timeoffTabSelected, actionFrom))
        }
    }
    handleStartDateChange = (date) => {
        const f = this.state.filterFields;
        const field = 'startDate';
        f[field] = date;
        this.setState({
            filterFields: f
        },()=>this.getUsersByStatus(f.employeeStatus))
    };
    handleEndDateChange = (date) => {
        const f = this.state.filterFields;
        const field = 'endDate';
        f[field] = date;
        this.setState({
            filterFields: f
        },()=>this.getUsersByStatus(f.employeeStatus))
    };
    getTimeOffDetails = (tab, actionFrom = '') =>{
        if (this.state.tab !== tab) {
            this.setState({
                saveFilter: false,
            });
        }
        let filter = this.state.filterFields;
        if (actionFrom === '') {
            filter = this.getSavedFilter(tab); 
        }
        let apiEndpoint = 'api/request/timeoff/list';
        let status = filter.requestStatus;
        let data = {
            'tab' : tab ? tab : this.state.timeoffTabSelected,
            'employee_status' : filter.employeeStatus,
            'status' : status,
            'start_date' : filter.startDate,
            'end_date' : filter.endDate,
            'timeoff_task' : filter.task,
            'user_id' : filter.userId,
        };
        this.setState({showLoader : true});
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data.permission) {
                    let requests = response.data.requests;
                    let requestStatus = response.data.request_status ? response.data.request_status : [];
                    let employeeStatus = response.data.employee_status ? response.data.employee_status : [];
                    let filterTOTasks = response.data.timeoff_tasks ? response.data.timeoff_tasks : [];
                    let filterFields = filter;
                    this.setState({
                        requestView : response.data.request_view,
                        tab: response.data.tab,
                        timeoffEditPermission : response.data.edit_permission,
                        timeoffReviewPermission : response.data.review_permission,
                        externalUser : response.data.external_user,
                        showLoader : false,
                        dataLoaded : true,
                        timeoffGrantPermission : response.data.show_grant_button,
                    });
                    if (response.data.tab !== this.state.timeoffTabSelected) {
                        let obj = { page: "timeoff", selected: response.data.tab }
                        this.props.setTabIndexValue(obj);
                    }
                    if (response.data.payperiod && !filter.startDate) {
                        filter.startDate = response.data.payperiod.first_day;
                        filter.endDate = response.data.payperiod.last_day;
                    }
                    let startDateArr = filterFields.startDate.split('-');
                    let startDateObj = new Date(startDateArr[0], startDateArr[1]-1, startDateArr[2]);
                    let endDateArr = filterFields.endDate.split('-');
                    let endDateObj = new Date(endDateArr[0], endDateArr[1]-1, endDateArr[2]);
                    if (requests) {
                        if (response.data.tab === 'my') {
                             this.setState({
                                myRequests : requests,
                                requestStatus : requestStatus,
                                employeeStatus : employeeStatus,
                                filterTOTasks : filterTOTasks,
                                filterFields : filter,
                                filterViewState : false,
                                allRequestViewPermission : response.data.allrequest_view,
                                reviewCount : response.data.review_count,
                                periodStartDate :startDateObj,
                                periodEndDate :endDateObj,
                            });
                        } else if (response.data.tab === 'all') {
                            this.setState({
                                allRequests : requests,
                                requestStatus : requestStatus,
                                employeeStatus : employeeStatus,
                                filterTOTasks : filterTOTasks,
                                filterFields : filter,
                                filterViewState : false,
                                allRequestViewPermission : response.data.allrequest_view,
                                reviewCount : response.data.review_count,
                                periodStartDate :startDateObj,
                                periodEndDate :endDateObj,
                                timeoffTabSelected : response.data.tab,
                            }, () => { this.getUsersByStatus(this.state.filterFields.employeeStatus); });
                        } else if (response.data.tab === 'review') {
                            this.setState({
                                reviewRequests : requests,
                                requestStatus : requestStatus,
                                employeeStatus : employeeStatus,
                                filterTOTasks : filterTOTasks,
                                filterFields : filter,
                                filterViewState : false,
                                reviewCount : response.data.review_count,
                                allRequestViewPermission : response.data.allrequest_view,
                                periodStartDate :startDateObj,
                                periodEndDate :endDateObj,
                                timeoffTabSelected : response.data.tab,
                            }, () => { this.getUsersByStatus(this.state.filterFields.employeeStatus); });
                        }
                    }
                } else {
                    this.setState({
                        listViewPermission : false,
                        showLoader : false,
                        dataLoaded : true,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    handleChange = (event, value) => {
        let tab = '';
        switch (value) {
            case 0:
                tab = 'my';
                break;
            case 1:
                tab = 'review';
                break;
            case 2:
                tab = 'all';
                break;
            default:
                tab = 'my';
        }
        let obj = { page: "timeoff", selected: tab }
        this.props.setTabIndexValue(obj);
        this.setState({ timeoffTabSelected: tab, });
    }
   
    createTimeOffClick = (id = 0) => {
        let editId = 0;
        if (id) {
            editId = id;
        }
        this.setState({
            open : true,
            editId : editId,
            showLoader: true,
            grantOpen : false,
        });
        if (!id) {
            this.MixpanelManager.trackEvent('Request', {'module' : 'Request', 'feature' : 'timeoff', 'action' : 'create-timeoff-request-clicked'});
        }
    }
    grantTimeOffClick = (id = 0) => { 
        let editId = 0;
        if (id) {
            editId = id;
        }
        this.setState({
            grantOpen : true,
            open : false,
            editId : editId,
            showLoader: true,
        });
    }    
    closeLoader = () => {
        this.setState({
            showLoader: false,
        });
    }
    handleViewClick = (id) => {
        if(id) {
            history.push('timeoff/view/' + id);
        }
    }
    updateParentOnAddSuccess = (successFlag, attachmentMsg = '',customMessage = '', updateType = 'create') => {
        this.setState({open : false, grantOpen : false});
        if (successFlag) { // Update list data
            let editId = this.state.editId;
            let msg = '';
            if (updateType == 'grant') {
                msg = "Request granted successfully.";
            } else {
                if (editId) {
                    msg = "Request updated successfully";
                    editId = 0;
                } else {
                    msg = "Request submitted successfully";
                }
            }            
            if (customMessage != '') {
                msg = customMessage;
            }
            if (attachmentMsg != '') {
                msg = msg+". "+attachmentMsg;
            }
            this.getTimeOffDetails('my','filter');
            this.setState({
                snackbarOpen : true,
                snackbarMsg : msg,
            });
        }
    }
    filterView = (anchor, open) => (event) => {
        this.setState({filterViewState : open});
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (this.state.filterFields.startDate > this.state.filterFields.endDate) {
            errors["endDate"] = "End date should be greater than start date";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }
    filterSubmit = (tab) =>{
        if (this.handleValidation()) {
            this.saveFilterToStore();
            if (this.state.saveFilter) {
                this.saveFilter();
            } else {
                this.getTimeOffDetails(this.state.timeoffTabSelected, 'filter');
            }
        }
    }
    handleFilterChange = (evt, value) => {
        const f = this.state.filterFields;
        const name = evt.target.name;
        if (name === 'saveFilter') {
            this.setState({
                saveFilter : value
            });
        } else {
            if (name === 'employeeStatus') {
                this.getUsersByStatus(evt.target.value);
            }
            f[name] = evt.target.value;
            this.setState({
                filterFields : f
            });
        }
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    getUsersByStatus = (status) => {
        let tab = this.state.timeoffTabSelected;
        let filter = this.state.filterFields;
        let apiEndpoint = 'api/request/timeoff/listusersbystatus';
        return httpServices.post(apiEndpoint,{status : status, tab : tab , start_date: filter.startDate, end_date: filter.endDate})
        .then(response => {
            if (response.data) {
                this.setState({
                    users : response.data,
                }); 
            } 
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    resetFilter = (tab) => {
        let ff = this.state.filterFields; 
        ff['startDate'] = null;
        ff['endDate'] = null;
        ff['requestStatus'] = tab === 'review' ? 1:0;
        ff['userId'] = '0';
        ff['employeeStatus'] = '0';
        ff['task'] = '0';
        this.setState({
            filterFields: ff,
            right: false,
            saveFilter : false,
        },()=>{
                this.deleteSavedFilter(tab);
            }
        );
    }
    getSavedFilter = (tab) => {
        let defaultfilter = filterFields[tab];
        let filter = defaultfilter;
        let savedFilterValues = this.props.savedFilterValues;
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '') {
            relativeUrl = relativeUrl + '#' + tab;
            let indexValue = savedFilterValues.findIndex((element) => element.relative_url === relativeUrl);
            if (indexValue !== -1) {
                let savedFilter = savedFilterValues[indexValue]['filter_options'];
                filter = Object.assign(defaultfilter, savedFilter);
            }
        }
        return filter;
    }
    saveFilter = () => {
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '' && this.state.filterFields) {
            relativeUrl = relativeUrl+'#'+this.state.tab;
            let apiEndpoint = 'api/user/save-filter';
            let data = {
                relative_url : relativeUrl,
                filter_options : this.state.filterFields,
            }
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                    this.getTimeOffDetails(this.state.timeoffTabSelected, 'filter');
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    saveFilterToStore = () => {
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '' && this.state.filterFields) {
            relativeUrl = relativeUrl + '#' + this.state.tab;
            let obj = {relative_url : relativeUrl, filter_options : this.state.filterFields}
            this.props.updateSavedFilterVal({obj});
        }
    }
    deleteSavedFilter = (tab) => {
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '') {
            relativeUrl = relativeUrl+'#'+tab;
            let apiEndpoint = 'api/user/delete-filter';
            let data = {
                relative_url : relativeUrl,
            }
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                    this.filterSubmit(tab, 'filter');
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    render(){
        return(
            <>
                <CustomProgressBar loaderOpen={this.state.showLoader}/>
                {(this.state.dataLoaded) ?
                    <>
                        {(this.state.listViewPermission) ?
                        <TimeOffComponent 
                            timeoffTabSelected={this.state.timeoffTabSelected}
                            handleChange={this.handleChange}
                            Requests = {this.state.requests}
                            GetTimeOffDetails = {this.getTimeOffDetails}
                            MyRequests = {this.state.myRequests}
                            AllRequests = {this.state.allRequests}
                            ReviewRequests = {this.state.reviewRequests}
                            Open = {this.state.open}
                            GrantOpen = {this.state.grantOpen}
                            CreateTimeOffClick = {this.createTimeOffClick}
                            GrantTimeOffClick = {this.grantTimeOffClick}
                            HandleViewClick = {this.handleViewClick}
                            UpdateParentOnAddSuccess = {this.updateParentOnAddSuccess}
                            FilterView = {this.filterView}
                            FilterViewState = {this.state.filterViewState}
                            FilterSubmit = {this.filterSubmit}
                            HandleFilterChange = {this.handleFilterChange}
                            FilterFields = {this.state.filterFields}
                            Users = {this.state.users}
                            RequestStatus = {this.state.requestStatus}
                            EmployeeStatus = {this.state.employeeStatus}
                            FilterTOTasks = {this.state.filterTOTasks}
                            EditId = {this.state.editId}
                            SnackbarOpen = {this.state.snackbarOpen}
                            SnackbarMsg = {this.state.snackbarMsg}
                            SnackbarClose = {this.snackbarClose}
                            ReviewCount = {this.state.reviewCount}
                            AllRequestViewPermission = {this.state.allRequestViewPermission}
                            RequestView = {this.state.requestView}
                            TimeoffEditPermission = {this.state.timeoffEditPermission}
                            TimeoffReviewPermission = {this.state.timeoffReviewPermission}
                            TimeoffGrantPermission = {this.state.timeoffGrantPermission}
                            ExternalUser = {this.state.externalUser}
                            CloseLoader = {this.closeLoader}
                            handleStartDateChange={this.handleStartDateChange}
                            handleEndDateChange={this.handleEndDateChange}
                            PeriodStartDate = {this.state.periodStartDate}
                            PeriodEndDate = {this.state.periodEndDate}
                            ResetFilter = {this.resetFilter.bind(this)}
                            errors = {this.state.errors}
                            SaveFilter = {this.state.saveFilter}
                            />
                        : <ErrorPage/>
                        }
                    </>
                :<></>}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    const selectedTimeoffTab = state.Tabs.allTabs['timeoff'] ? state.Tabs.allTabs['timeoff'] : 'my';
    const savedFilterValues =  state.savedFilters.savedFilterValues ? state.savedFilters.savedFilterValues : [];
    return {
        selectedTimeoffTab: selectedTimeoffTab,
        savedFilterValues: savedFilterValues
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setSavedFilterVal: () => dispatch(setSavedFilters()),
        setTabIndexValue: (obj) => dispatch(Tabs.actions.setSelectedTabIndex(obj)),
        updateSavedFilterVal: ({obj}) => dispatch(updateSavedFilterAction({obj}))
    }
}
const connectedTimeOffContainer = connect(mapStateToProps, mapDispatchToProps)(TimeOffContainer);
export { connectedTimeOffContainer as TimeOffContainer };

